import React from "react"
import AnchorScroll from "react-anchor-link-smooth-scroll"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Link } from "gatsby"

// Hooks & Querys
import { useSanityPath } from "../../hooks"

export const PageLink = ({ target, title, ...rest }) => {
  const path = useSanityPath(target)
  return (
    <Link to={path}>
      {title}
    </Link>
  )
}

export const ColorPageLink = ({ reference, color, children, props, ...rest }) => {
  const path = useSanityPath(reference)
  return (
    <Link style={{ color:color.value }} to={path}>
      {children}
    </Link>
  )
}

export const PageLinkButton = ({ page, linkText, ...rest }) => {
  const path = useSanityPath(page)
  return (
    <div className="mt-6 text-base">
      <Link className="btn-rounded-hollow" to={path}>
      {linkText}
    </Link>
    </div>
  )
}

export const PathLink = ({ target, title, ...rest }) => {
  return (
    <Link to={`/${target}`} {...rest}>
      {title}
    </Link>
  )
}

export const PageAnchorLink = ({ anchor, page, linkText, ...rest }) => {
  const path = useSanityPath(page)
  return (
    <AnchorLink to={`/${path}#${anchor}`} {...rest}>
      {linkText}
    </AnchorLink>
  )
}

export const AnchorScrollLink = ({ anchor, linkText, ...rest }) => {
  return (
    <AnchorScroll to={`#${anchor}`} {...rest}>
      {linkText}
    </AnchorScroll>
  )
}

export const ExternalLink = ({ linkText, link, ...rest }) => {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      {linkText}
    </a>
  )
}

export const ExternalButtonLink = ({ linkText, path, ...rest }) => {
  return (
    <div className="mt-6 text-base">
      <a href={path} target="_blank" rel="noreferrer" className="btn-rounded-hollow">
      {linkText}
      </a>      
    </div>
    
  )
}
