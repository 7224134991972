import { graphql, useStaticQuery } from "gatsby"

import { QueryFragments } from "./queryFragments" // eslint-disable-line

export const useSettingsFooter = () => {
  const { sanitySettingsFooter } = useStaticQuery(graphql`
    query {
      sanitySettingsFooter {
        subscribeForm
        formText
        buttonText
        footerNav {
          title
          name
          sections {
            title
            link_type
            path
            _key
            external
            target {
              _key
              _type
              slug {
                current
              }
            }
            links {
              title
              link_type
              path
              external
              target {
                _key
                _type
                title
                slug {
                  current
                }
              }
            }
          }
        }        
      }
    }
  `)
  return sanitySettingsFooter || {}
}
