exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-community-js": () => import("./../../../src/pages/news/community.js" /* webpackChunkName: "component---src-pages-news-community-js" */),
  "component---src-pages-news-corporate-js": () => import("./../../../src/pages/news/corporate.js" /* webpackChunkName: "component---src-pages-news-corporate-js" */),
  "component---src-pages-news-events-js": () => import("./../../../src/pages/news/events.js" /* webpackChunkName: "component---src-pages-news-events-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-news-media-press-js": () => import("./../../../src/pages/news/media-press.js" /* webpackChunkName: "component---src-pages-news-media-press-js" */),
  "component---src-pages-news-mums-all-together-js": () => import("./../../../src/pages/news/mums-all-together.js" /* webpackChunkName: "component---src-pages-news-mums-all-together-js" */),
  "component---src-pages-news-yacht-js": () => import("./../../../src/pages/news/yacht.js" /* webpackChunkName: "component---src-pages-news-yacht-js" */),
  "component---src-templates-article-corporate-js": () => import("./../../../src/templates/articleCorporate.js" /* webpackChunkName: "component---src-templates-article-corporate-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-mat-js": () => import("./../../../src/templates/articleMat.js" /* webpackChunkName: "component---src-templates-article-mat-js" */),
  "component---src-templates-article-yacht-js": () => import("./../../../src/templates/articleYacht.js" /* webpackChunkName: "component---src-templates-article-yacht-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

