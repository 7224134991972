import React from 'react'
import { Link } from 'gatsby'
import useScrollPosition from '@react-hook/window-scroll'
import { Helmet } from 'react-helmet'

import { useSiteState } from '../lib/siteContext'

import { AnimatePresence, motion } from 'framer-motion'

import '../css/main.css'

import { useLocation } from '@reach/router'

import { Header } from '../components/nav/header'
import { Footer } from '../components/nav/footer'
import { FatLogo } from '../components/ui'

const Layout = ({ children }) => {
  const { pathname } = useLocation()
  const { homeHeader } = useSiteState()
  const scrolledY = useScrollPosition(5)
  return (
    <div className="block h-auto">
      <Helmet>
        <meta
          name="google-site-verification"
          content="h_0RFcGxZz1eCyWJHuYqE_fFzfeuEM1tgKAnqxRwpFQ"
        />
      </Helmet>
      <Link
        to={`/`}
        className={`fixed z-30 left-4 w-28 h-auto trans-std text-doc-text ${
          !scrolledY
            ? 'top-16 lg:w-logo-desk'
            : 'top-4 lg:w-logo-desk-scrolled lg:top-gutter'
        }`}
      >
        <FatLogo />
      </Link>
      <Header />
      <AnimatePresence>
        <motion.main className={`w-full trans-std z-0`} key={pathname}>
          {children}
          <Footer />
        </motion.main>
      </AnimatePresence>
    </div>
  )
}

export default Layout
