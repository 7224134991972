import React from 'react';
import { SiteContextProvider } from './src/lib/siteContext';
import { AnimatePresence } from 'framer-motion';

export const wrapRootElement = ({ element }) => (
    <SiteContextProvider>{element}</SiteContextProvider>
  );
  
  export const wrapPageElement = ({ element }) => (
    <AnimatePresence exitBeforeEnter initial={false}>
      {element}
    </AnimatePresence>
  );
  
  export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    // transition duration from `layout.js` * 1000 to get time in ms
    // * 2 for exit + enter animation
    // const TRANSITION_DELAY = 0.3 * 1500 * 2;
    const TRANSITION_DELAY = 0;
    // if it's a "normal" route
    if (location.action === 'PUSH') {
      window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY);
    }
    // if we used the browser's forwards or back button
    else {
      const savedPosition = getSavedScrollPosition(location) || [0, 0];
      window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY);
    }
    return false;
  };
  