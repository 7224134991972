import { useStaticQuery, graphql } from 'gatsby';

import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

export const useAnnouncementQuery = () => {
  const { sanityAnnouncementBar } = useStaticQuery(graphql`
    query {
      sanityAnnouncementBar {
        mobileAnnouncement
        mobileAnnouncementColorText
        deskAnnouncement
        deskAnnouncementColorText
        announcementLink,
        topNav {
            title
            name
            sections {
              _key
              title
              target {
                slug {
                  current
                }
              }
              links {
                title
                target {
                  title
                  slug {
                    current
                  }
                }
              }
            }
          }        
      }
    }
  `);
  return sanityAnnouncementBar || {};
};
