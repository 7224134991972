import React, { useState } from "react"
import loadable from "@loadable/component"

import { motion, AnimatePresence } from "framer-motion"

import { useInView } from "react-intersection-observer"

import { wrapper } from "./videoModule.module.css"

// SVG
import PlayIcon from "../../svg/play.svg"

const VideoComponent = loadable(() => import("../media/standardVideo"))

export const VideoModule = ({ video }) => {
  const [isActive, setActive] = useState(false)

  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
    triggerOnce: true,
  })

  return (
    <div className="bg-black text-white" ref={ref}>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={`video-${isActive}`}
          className={`${wrapper}`}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            y: 0,
          }}
          exit={{
            opacity: 0,
          }}
        >
          {!isActive && (
            <button
              className="absolute z-10 top-0 left-0 flex items-center justify-center w-full h-full bg-black bg-opacity-25"
              onClick={() => setActive(true)}
            >
              <div>
                <PlayIcon />
              </div>
            </button>
          )}
          {inView && (
            <VideoComponent
              url={video}
              playing
              muted={!isActive}
              loop={!isActive}
              controls={isActive}
              width="100%"
              height="auto"
              onEnded={() => setActive(false)}
              playsinline={!isActive}
            />
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
