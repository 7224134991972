import React, { useState } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp'


function FooterForm() {
    const [email, setEmail] = useState(""); 
    const [submitted, setSubmitted] = useState(false);  

    const handleSubmit= (e) => {
        e.preventDefault();
        console.log('email address', email);
        addToMailchimp(email) // listFields are optional if you are only capturing the email address.
        .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        console.log(data)
        setSubmitted(true)
        })
        .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
        })
    }

 



return (
    <form onSubmit={e => {handleSubmit(e)}} className="flex flex-row flex-nowrap items-center">
       <input type="email" name="email" placeholder="Email address" className="w-full mb-4 lg:mb-0 mr-3 input-text"  onChange={(e) => setEmail(e.target.value)} />
      {submitted ? (
          <p>Thanks for subscribing.</p>
      ) : (
        <button className="appearance-none font-normal" type="submit">Subscribe</button>
      )}
    </form>
  )
}

export default FooterForm