import React from 'react';
import { Link } from 'gatsby';
import FooterForm  from './footerForm'
import { useSanityPath } from "../../hooks"

// Hooks & Querys
import { useSiteState } from '../../lib/siteContext';
import { useSettingsFooter } from '../../GraphQl/useSettingsFooter';

// SVG
import { FatLogo } from "../ui"

// Components
// import { SubscribeForm } from '../forms/subscribeForm';

const FooterLink = ({title, target, external, link_type, path, _key }) => {
  const pageLink = useSanityPath(target)  
  return (
    <div key={_key} className="col-span-1">
    {link_type === 'page' && <Link to={pageLink} >{title}</Link>}
      {link_type === 'path' && <Link to={`/${path}`} >{title}</Link>}        
      {link_type === 'external' && <a href={external} target="_blank" rel="noreferrer" className="mr-8">{title}</a>}        
    </div>
  )
}

export const Footer = () => {
  const { subscribeForm, formText, buttonText, footerNav } = useSettingsFooter();
  const { homeHeader } = useSiteState();
  return (
    <footer className="py-8 grid-std gap-y-8 lg:gap-y-0 relative z-10 md:pt-32 lg:pt-60 xl:pt-68 h-auto overflow-y-hidden">      
    <Link to={`/`} className={`absolute bottom-0 left-0 w-logo-footermob transform translate-y-16 md:w-1/2 lg:w-2/5 2xl:2-2/6 max-w-lg z-0 h-auto trans-std ${homeHeader ? 'text-doc-logo' : 'text-doc-text'}`}><FatLogo classes="opacity-30" /></Link>          
    <div className="col-start-2 col-end-8 row-start-2 lg:row-start-1 lg:col-start-6 lg:col-end-12 z-10">
    <nav className="grid grid-cols-1 gap-y-2 lg:grid-cols-2 lg:gap-x-gutter">
        {footerNav.sections.map(section => {          
          return (
          <FooterLink key={section._key} {...section} />
          )
        })}
      </nav>
    </div>
    <div className="col-start-2 col-end-8 row-start-1 lg:col-start-13 lg:col-end-18 mb-12 lg:mb-0">
      {subscribeForm && (
        <>
        {formText && <p className="w-3/4 mb-5">{formText}</p>}
        <FooterForm buttonText={buttonText} />        
        </>
      )}
    </div>
    <div className="col-start-2 col-end-8 row-start-3 lg:row-start-1 lg:col-end-5">FITNESSALLTOGETHER ©</div>
    </footer>
  );
};
