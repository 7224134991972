import React, { useState } from 'react'
import { Link } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import useScrollPosition from '@react-hook/window-scroll'

// Hooks & State
import { useSiteState } from '../../lib/siteContext'
import { useSanityPath } from '../../hooks'
import { useSettingsNav } from '../../GraphQl/useSettingsNav'
import { useAnnouncementQuery } from '../../GraphQl/useAnnouncementQuery'

// Components
import { MobileNav } from './mobileNav'
// import { NavLink } from "./navLink"

const ParentLink = ({
  _key,
  path,
  external,
  target,
  title,
  link_type,
  links,
}) => {
  // this link will be active when itself or deeper routes
  // are current
  const pageLink = useSanityPath(target)
  return (
    <div key={_key} className="mx-4 relative">
      {link_type === 'page' && (
        <Link
          to={pageLink}
          className="parent-link"
          partiallyActive={true}
          activeClassName="active underline"
        >
          {title}
        </Link>
      )}
      {link_type === 'path' && (
        <Link
          to={`/${path}`}
          className="parent-link"
          partiallyActive={true}
          activeClassName="active underline"
        >
          {title}
        </Link>
      )}
      {link_type === 'external' && (
        <a
          href={external}
          target="_blank"
          rel="noreferrer"
          className="parent-link"
        >
          {title}
        </a>
      )}
      <div className="sub-nav z-10 absolute top-full mt-8 left-0 bottom-0 w-screen justify-start items-center hidden">
        {links.map((sublink, index) => (
          <SubLink key={sublink._key} index={index} {...sublink} />
        ))}
      </div>
    </div>
  )
}

const SubLink = ({
  link_type,
  path,
  zingfit,
  external,
  title,
  target,
  _key,
}) => {
  const pageLink = useSanityPath(target)
  return (
    <>
      {link_type === 'page' && (
        <Link
          key={_key}
          to={pageLink}
          className="mr-8"
          activeClassName="active underline"
        >
          {title}
        </Link>
      )}
      {link_type === 'path' && (
        <Link
          key={_key}
          to={`/${path}`}
          className="mr-8"
          activeClassName="active underline"
        >
          {title}
        </Link>
      )}
      {link_type === 'zingfit' && (
        <a key={_key} href={`/${zingfit}`} className="mr-8">
          {title}
        </a>
      )}
      {link_type === 'external' && (
        <a
          key={_key}
          href={external}
          target="_blank"
          rel="noreferrer"
          className="mr-8"
        >
          {title}
        </a>
      )}
    </>
  )
}

export const Header = () => {
  const { mainNav } = useSettingsNav()
  const {
    mobileAnnouncement,
    mobileAnnouncementColorText,
    deskAnnouncement,
    deskAnnouncementColorText,
    announcementLink,
    topNav,
  } = useAnnouncementQuery()
  const { toggleNav } = useSiteState()
  const breakpoints = useBreakpoint()
  const [selected, setSelected] = useState()
  const scrolledY = useScrollPosition(5)

  const whatLocation = (props) => {
    const currentLocation = props.pathname
    if (currentLocation.includes('blog')) {
      return { className: 'active' }
    }
  }

  return (
    <>
      <div
        className={`bg-white z-30 text-fat-blue lg:px-gutter lg:py-4 w-full flex justify-between heading uppercase transform trans-std hide-mob`}
      >
        {deskAnnouncement && deskAnnouncementColorText && announcementLink && (
          <a
            href={announcementLink}
            target="_blank"
            rel="noreferrer"
            className="heading uppercase"
          >
            {deskAnnouncement}{' '}
            <span className="text-fat-yellow">{deskAnnouncementColorText}</span>
          </a>
        )}
        <div className="flex items-center">
          {topNav.sections.map((section, index) => (
            <Link
              key={section._key}
              to={`/${section?.target?.slug.current}`}
              section={section.title}
            />
          ))}
          <a href="https://momence.com/sign-in" className="">
            Create Account
          </a>
        </div>
      </div>
      <div
        className={`bg-white z-30 text-fat-blue px-gutter py-4 w-full heading uppercase transform trans-std text-mobileAnnounce lg:hidden`}
      >
        {mobileAnnouncement && mobileAnnouncementColorText && announcementLink && (
          <a
            href={announcementLink}
            target="_blank"
            rel="noreferrer"
            className="heading uppercase"
          >
            {mobileAnnouncement}{' '}
            <span className="text-fat-yellow">
              {mobileAnnouncementColorText}
            </span>
          </a>
        )}
      </div>
      <header
        className={`fixed top-0 w-full grid-std py-4 lg:py-0 z-20 trans-std ${
          !scrolledY ? 'top-14 lg:top-14' : 'top-0'
        }`}
      >
        <div className="col-start-5 col-end-8 row-start-1 flex justify-end items-center lg:hidden">
          <a className="btn-rounded-sm mr-4" href="/fat-workout/timetable">
            Book Now
          </a>
          <svg
            className="cursor-pointer stroke-current"
            onClick={toggleNav}
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
          >
            <line
              y1="14.1853"
              x2="17.2017"
              y2="14.1853"
              stroke="#18227B"
              strokeWidth="2"
            />
            <line y1="1" x2="17.2017" y2="1" stroke="#18227B" strokeWidth="2" />
            <line y1="8" x2="17.2017" y2="8" stroke="#18227B" strokeWidth="2" />
          </svg>
        </div>
        <nav className="main-nav row-start-1 lg:col-start-5 lg:col-end-16 flex justify-center items-center text-doc-text trans-std lg:py-12 text-logo hide-mob">
          {mainNav.sections.map((section, index) => (
            <ParentLink
              key={section._key}
              getProps={({ location }) => whatLocation(location)}
              index={index}
              selected={selected}
              setSelected={setSelected}
              {...section}
            />
          ))}
          <div className="mx-4">{selected}</div>
        </nav>
        <div className="row-start-1 lg:col-start-14 lg:col-end-18 flex items-center justify-end z-0 hide-mob">
          <a
            className="btn-rounded-hollow mr-3"
            href="https://momence.com/video/plugin/39964"
            target="_blank"
            rel="noreferrer"
          >
            FAT On Demand
          </a>
          <a className="btn-rounded" href="/fat-workout/timetable">
            Book Now
          </a>
        </div>
      </header>
      {breakpoints.md && <MobileNav mainNav={mainNav} />}
    </>
  )
}
