import React from 'react';
import Image from "gatsby-plugin-sanity-image"
import { PortableTextBlock } from "../sanity/portableTextBlock"

export const TouchpointCard = ({ classes, title, text, image, layout, textAlign  }) => {
  return (
    <>
    {layout === 'grid' ? (
        <div className={`${classes} grid grid-cols-1 grid-rows-1`}>    
            <div className="w-full pb-card-square relative col-span-full row-start-1 bg-white">
                {image && <Image className="absolute inset-0 object-cover w-full h-full" {...image}/>}                
            </div>
            <div className={`col-span-full row-start-1 z-10 py-2 px-gutter lg:p-gutter flex flex-col ${textAlign}`}>
                <h4 className="pt-4 pb-3 uppercase heading text-h6 lg:text-h3">{title}</h4>
                <div className="w-4/5">{text && <PortableTextBlock text={text} />}</div>        
            </div>        
        </div>
    ) : (
        <div className={`${classes} col-span-1 lg:grid lg:grid-cols-15 lg:gap-x-gutter`}>    
            <div className="w-full pb-card-landscape relative lg:col-start-1 lg:col-end-9">
                {image && <Image className="absolute inset-0 object-cover w-full h-full" {...image}/>}
            </div>
            <div className="lg:col-start-9 lg:col-end-15 pb-2">
                <h4 className="pt-4 pb-3 uppercase heading text-h6 lg:text-h3">{title}</h4>
                <div className="w-4/5 text-h6">{text && <PortableTextBlock text={text} />}</div>        
            </div>        
        </div>
    )}
    </>
  )
};
