import { graphql, useStaticQuery } from "gatsby"

import { QueryFragments } from "./queryFragments" // eslint-disable-line

export const useSettingsNav = () => {
  const { sanitySettingsNav } = useStaticQuery(graphql`
    query {
      sanitySettingsNav {
        mainNav {
          title
          name
          sections {
            _key
            _type
            title
            link_type
            path
            external
            target {
              _type
              _key
              slug {
                current
              }
            }
            links {
              _key
              _type
              title
              link_type
              path
              external
              zingfit
              target {
                _type
                title
                slug {
                  current
                }
              }
            }
          }
        }        
      }
    }
  `)
  return sanitySettingsNav || {}
}
